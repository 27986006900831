<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#394755"/>
    <path d="M15.6 12.6C16.0774 12.6 16.5352 12.7897 16.8728 13.1272C17.2104 13.4648 17.4 13.9226 17.4 14.4C17.4 14.5592 17.4632 14.7118 17.5757 14.8243C17.6883 14.9368 17.8409 15 18 15C18.1591 15 18.3117 14.9368 18.4243 14.8243C18.5368 14.7118 18.6 14.5592 18.6 14.4C18.6 13.6044 18.2839 12.8413 17.7213 12.2787C17.1587 11.7161 16.3956 11.4 15.6 11.4C15.4409 11.4 15.2883 11.4632 15.1757 11.5758C15.0632 11.6883 15 11.8409 15 12C15 12.1592 15.0632 12.3118 15.1757 12.4243C15.2883 12.5368 15.4409 12.6 15.6 12.6Z" fill="#FF8100"/>
    <path d="M15.6 10.2C16.7139 10.2 17.7822 10.6425 18.5698 11.4302C19.3575 12.2178 19.8 13.2861 19.8 14.4C19.8 14.5591 19.8632 14.7117 19.9757 14.8243C20.0883 14.9368 20.2409 15 20.4 15C20.5591 15 20.7117 14.9368 20.8243 14.8243C20.9368 14.7117 21 14.5591 21 14.4C21 12.9678 20.4311 11.5943 19.4184 10.5816C18.4057 9.56893 17.0322 9 15.6 9C15.4409 9 15.2883 9.06321 15.1757 9.17574C15.0632 9.28826 15 9.44087 15 9.6C15 9.75913 15.0632 9.91174 15.1757 10.0243C15.2883 10.1368 15.4409 10.2 15.6 10.2ZM20.85 17.346C20.817 17.2497 20.76 17.1633 20.6845 17.0951C20.6089 17.0269 20.5172 16.979 20.418 16.956L16.818 16.134C16.7203 16.1118 16.6186 16.1145 16.5221 16.1418C16.4257 16.169 16.3377 16.22 16.266 16.29C16.182 16.368 16.176 16.374 15.786 17.118C14.4919 16.5217 13.455 15.4805 12.864 14.184C13.626 13.8 13.632 13.8 13.71 13.71C13.78 13.6383 13.831 13.5503 13.8582 13.4539C13.8855 13.3574 13.8882 13.2557 13.866 13.158L13.044 9.6C13.021 9.50082 12.9731 9.40912 12.9049 9.33355C12.8367 9.25797 12.7503 9.201 12.654 9.168C12.5139 9.11795 12.3692 9.08178 12.222 9.06C12.0704 9.02484 11.9156 9.00474 11.76 9C11.028 9 10.326 9.29078 9.80839 9.80839C9.29078 10.326 9 11.028 9 11.76C9.00318 14.2096 9.97769 16.558 11.7098 18.2902C13.442 20.0223 15.7904 20.9968 18.24 21C18.6024 21 18.9613 20.9286 19.2962 20.7899C19.6311 20.6512 19.9353 20.4479 20.1916 20.1916C20.4479 19.9353 20.6512 19.6311 20.7899 19.2962C20.9286 18.9613 21 18.6024 21 18.24C21.0002 18.0873 20.9881 17.9348 20.964 17.784C20.9388 17.635 20.9006 17.4884 20.85 17.346Z" fill="#FF8100"/>
  </svg>
</template>

<script>
export default {
  name: 'CallSmallIcon'
}
</script>
