<template>
  <Moveable class="call" @drag="handleDrag" v-bind="moveable" v-if="$store.state.call">
    <div class="call__header">
      <label class="call__header__title">Входящий звонок</label>
      <a @click="fold = !fold" v-if="$store.state.call.contacts && $store.state.call.contacts.length" class="call__header__button" href="javascript:void(0);">{{ fold ? 'Развернуть' : 'Свернуть'}}</a>
    </div>
    <div class="call__body" v-if="!fold">
      <div class="call__person">
        <div class="call__person__icon">
          <call-icon/>
        </div>
        <router-link :to="{ name: 'contact', params: {id: $store.state.call.contacts[0]._id} }" class="call__person__name" v-if="$store.state.call.contacts && $store.state.call.contacts.length">
          <h1>{{ $store.state.call.contacts[0].name }}</h1>
        </router-link>
        <h1 class="call__person__name" v-else>Неизвестный номер</h1>
        <router-link :to="{ name: 'company', params: {id: $store.state.call.company._id} }" class="call__person__name" v-if="$store.state.call.company">
          <h2 class="call__person__company" v-if="$store.state.call.company">{{ $store.state.call.company.name }}</h2>
        </router-link>
        <a :href="`tel:${$store.state.call.phone}`" v-if="$store.state.call.phone" class="call__person__phone">{{ $store.state.call.phone }}</a>
      </div>
      <div class="call__trades" v-if="$store.state.call.leads && $store.state.call.leads.length">
        <label class="call__trades__title">Сделки</label>
        <perfect-scrollbar tag="ul" class="call__trades__list">
          <li v-for="(lead, index) in $store.state.call.leads" :key="index">
            <time :datetime="lead.createdAt" v-if="lead && lead.createdAt">{{ getDate(item.createdAt) }}</time>
            <router-link :to="{ name: 'trade', params: { id: lead._id} }" class="call__trades__item">
              <div class="call__trades__item__status">
                <span v-if="lead && lead.status">{{ lead.status }}</span>
                <span v-else>Без статуса</span>
              </div>
              <div class="call__trades__item__info">
                <div>
                  <span>Имущество:</span>
                  <span v-if="lead && lead.name" class="orange">{{ lead.name }}</span>
                  <span v-else class="orange">Без имени</span>
                  <span v-if="lead && lead.summ">{{ lead.summ }} руб.</span>
                  <span v-else>0 руб.</span>
                </div>
                <div>
                  <span>Ответственный:</span>
                  <span class="white" v-if="lead && lead.responsible_user_id">{{ getResponsibleName(lead.responsible_user_id) }}</span>
                  <span class="white" v-else>отсутствует</span>
                </div>
              </div>
            </router-link>
          </li>
        </perfect-scrollbar>
      </div>
      <!--      <div class="call__status">-->
      <!--        <label class="call__status__name">Соединение...</label>-->
      <!--        <span class="call__status__time">Продолжительность разговора 00:49 мин.</span>-->
      <!--      </div>-->
    </div>
    <div class="call__body" v-else>
      <div class="call__folded">
        <call-small-icon/>
        <div class="call__folded__info">
          <span class="call__folded__name" v-if="$store.state.call.contacts && $store.state.call.contacts.length">{{ $store.state.call.contacts[0].name }}</span>
          <!--          <span class="call__folded__status">Соединение...</span>-->
        </div>
      </div>
    </div>
    <div class="call__footer">
      <!--      <div class="call__footer__buttons">-->
      <!--        <button type="button" class="call__rb">-->
      <!--          <i aria-hidden="true">-->
      <!--            <pause-icon/>-->
      <!--          </i>-->
      <!--        </button>-->
      <!--        <button type="button" class="call__rb">-->
      <!--          <i aria-hidden="true">-->
      <!--            <microphone-icon/>-->
      <!--          </i>-->
      <!--        </button>-->
      <!--        <button type="button" class="call__rb">-->
      <!--          <i aria-hidden="true">-->
      <!--            <tile-icon/>-->
      <!--          </i>-->
      <!--        </button>-->
      <!--      </div>-->
      <div class="call__footer__buttons">
        <!--        <button type="button" class="call__b call__b&#45;&#45;green">-->
        <!--          <span>Принять</span>-->
        <!--          <i aria-hidden="true">-->
        <!--            <accept-call-icon/>-->
        <!--          </i>-->
        <!--        </button>-->
        <button type="button" class="call__b" @click="$store.state.isCalling = false">
          <span>Завершить</span>
          <i aria-hidden="true">
            <drop-call-icon/>
          </i>
        </button>
      </div>
    </div>
  </Moveable>
</template>

<script>
import CallIcon from '../svg/CallIcon'
// import PauseIcon from '../svg/PauseIcon'
// import MicrophoneIcon from '../svg/MicrophoneIcon'
// import TileIcon from '../svg/TileIcon'
import DropCallIcon from '../svg/DropCallIcon'
// import AcceptCallIcon from '../svg/AcceptCallIcon'
import Moveable from 'vue-moveable'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import CallSmallIcon from '../svg/CallSmallIcon'
import moment from 'moment'

export default {
  name: 'CallComponent',
  components: {
    CallSmallIcon,
    Moveable,
    PerfectScrollbar,
    // AcceptCallIcon,
    DropCallIcon,
    // TileIcon,
    // MicrophoneIcon,
    // PauseIcon,
    CallIcon
  },
  data () {
    return {
      moveable: {
        draggable: true,
        throttleDrag: 0,
        resizable: false,
        throttleResize: 1,
        keepRatio: false,
        scalable: true,
        throttleScale: 0,
        rotatable: true,
        throttleRotate: 0,
        pinchable: true, // ["draggable", "resizable", "scalable", "rotatable"]
        origin: false
      },
      fold: false
    }
  },
  methods: {
    getResponsibleName (id) {
      const user = this.$store.state.allUsers.filter(item => item.id === id)
      return user[0] ? user[0].name : ''
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    handleDrag ({ target, transform }) {
      target.style.transform = transform
    }
  }
}
</script>

<style lang="stylus">
.ps__thumb-y
  background-color $black !important

.call
  max-width 470px
  width 100%
  background: $darker;
  box-shadow: 0 30px 60px rgba($black, 0.15);
  border-radius: 10px;
  z-index 999999
  absolute right 32px top 90px
  display flex
  flex-direction column

  &__folded
    display flex
    align-items flex-start
    padding 14px 20px

    &__info
      display flex
      flex-direction column
      margin-left 15px

    &__name
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: $white;

    &__status
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: $white;

  &__status
    padding 12px 23px
    display flex
    flex-direction column
    align-items center
    margin 20px 0

    &__name
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: $white;
      margin-bottom 20px

    &__time
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: rgba($white, 0.5);

  &__b
    button($red, $white)
    padding 12px 14px
    margin 0 5px

    &--green
      background $green
      border $green

      &:hover
        background $green
        border $green

  &__rb
    background none
    border 1px solid $dark
    border-radius 100%
    width 40px
    height 40px
    display inline-flex
    align-items center
    justify-content center
    cursor pointer
    margin 5px

  &__footer
    display flex
    align-items center
    justify-content space-between
    padding 12px 23px

    &__buttons
      display flex
      align-items center

  &__header
    width 100%
    display flex
    align-items center
    justify-content space-between
    padding 10px 20px

    &__title
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      color: $white;

    &__button
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      text-align: right;
      color: $white;
      opacity: 0.5;

  &__body
    display flex
    flex-direction column
    background $dark

  &__person
    display flex
    flex-direction column
    align-items center
    padding 8px 32px 22px

    &__name
      font-family font_regular
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      margin 4px 0
      color: $white;

      h1
        font-family font_regular
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        margin 4px 0
        color: $white;

    &__company
      font-family font_regular
      margin 4px 0 2px
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      color: $white;
      opacity: 0.5;

    &__phone
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      color: $white;

  &__trades
    height 220px
    display flex
    flex-direction column
    margin 0 32px
    background: $darker;
    border-radius: 10px;
    margin-bottom 24px

    &__title
      width 100%
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      padding 10px
      flex-shrink 0
      border-bottom: 1px solid $dark;
      color: $white;

    &__item
      display flex
      align-items flex-start
      padding 6px 10px

      &:hover
        background: rgba($white, 0.05);

      &__status
        display flex
        background: $green;
        border-radius: 3px;
        font-weight: normal;
        font-size: 9px;
        line-height: 16px;
        align-items: center;
        color: $white;
        padding 0 3px

      &__info
        display flex
        margin-left 10px
        flex-direction column

        span
          margin-right 5px
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: rgba($white, 0.5);

          &.orange
            color $orange

          &.white
            color $white

        div
          width 100%
          display flex
          align-items center
          margin-bottom 12px

          &:last-child
            margin-bottom 6px

    &__list
      display flex
      flex-direction column
      height 100%
      overflow: hidden
      list-style none
      padding 0

      li
        display flex
        flex-direction column
        flex-shrink 0
        margin-bottom 6px

        time
          padding 10px
          width 100%
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          align-items: center;
          color: $white;
          opacity: 0.5;

.moveable-control-box
  display none !important
</style>
