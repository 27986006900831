<template>
  <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="52" cy="52" r="40" fill="#394755"/>
    <circle opacity="0.5" cx="52" cy="52" r="45.5" stroke="#394755"/>
    <circle opacity="0.2" cx="52" cy="52" r="51.5" stroke="#394755"/>
    <path d="M53 48C53.7956 48 54.5587 48.3161 55.1213 48.8787C55.6839 49.4413 56 50.2044 56 51C56 51.2652 56.1054 51.5196 56.2929 51.7071C56.4804 51.8946 56.7348 52 57 52C57.2652 52 57.5196 51.8946 57.7071 51.7071C57.8946 51.5196 58 51.2652 58 51C58 49.6739 57.4732 48.4021 56.5355 47.4645C55.5979 46.5268 54.3261 46 53 46C52.7348 46 52.4804 46.1054 52.2929 46.2929C52.1054 46.4804 52 46.7348 52 47C52 47.2652 52.1054 47.5196 52.2929 47.7071C52.4804 47.8946 52.7348 48 53 48Z" fill="#FF8100"/>
    <path
        d="M53 44C54.8565 44 56.637 44.7375 57.9497 46.0503C59.2625 47.363 60 49.1435 60 51C60 51.2652 60.1054 51.5196 60.2929 51.7071C60.4804 51.8946 60.7348 52 61 52C61.2652 52 61.5196 51.8946 61.7071 51.7071C61.8946 51.5196 62 51.2652 62 51C62 48.6131 61.0518 46.3239 59.364 44.636C57.6761 42.9482 55.3869 42 53 42C52.7348 42 52.4804 42.1054 52.2929 42.2929C52.1054 42.4804 52 42.7348 52 43C52 43.2652 52.1054 43.5196 52.2929 43.7071C52.4804 43.8946 52.7348 44 53 44ZM61.75 55.91C61.695 55.7495 61.6 55.6056 61.4741 55.4919C61.3481 55.3781 61.1953 55.2984 61.03 55.26L55.03 53.89C54.8671 53.8531 54.6976 53.8575 54.5369 53.9029C54.3762 53.9484 54.2294 54.0333 54.11 54.15C53.97 54.28 53.96 54.29 53.31 55.53C51.1532 54.5362 49.425 52.8009 48.44 50.64C49.71 50 49.72 50 49.85 49.85C49.9667 49.7306 50.0516 49.5838 50.0971 49.4231C50.1425 49.2624 50.1469 49.0929 50.11 48.93L48.74 43C48.7016 42.8347 48.6219 42.6819 48.5081 42.5559C48.3944 42.43 48.2505 42.335 48.09 42.28C47.8565 42.1966 47.6153 42.1363 47.37 42.1C47.1173 42.0414 46.8593 42.0079 46.6 42C45.38 42 44.21 42.4846 43.3473 43.3473C42.4846 44.21 42 45.38 42 46.6C42.0053 50.6827 43.6295 54.5967 46.5164 57.4836C49.4033 60.3705 53.3173 61.9947 57.4 62C58.0041 62 58.6022 61.881 59.1603 61.6498C59.7184 61.4187 60.2255 61.0798 60.6527 60.6527C61.0798 60.2255 61.4187 59.7184 61.6498 59.1603C61.881 58.6022 62 58.0041 62 57.4C62.0003 57.1455 61.9802 56.8913 61.94 56.64C61.898 56.3916 61.8344 56.1474 61.75 55.91Z"
        fill="#FF8100"/>
  </svg>
</template>

<script>
export default {
  name: 'CallIcon'
}
</script>
