<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.99849 5.53299C3.83156 5.2846 3.68444 5.02346 3.55849 4.75199C4.24049 4.39999 4.24599 4.39999 4.31749 4.31749C4.38168 4.2518 4.4284 4.17107 4.45338 4.08269C4.47835 3.9943 4.4808 3.90107 4.46049 3.81149L3.70699 0.549999C3.6859 0.459082 3.64201 0.375028 3.57947 0.305751C3.51693 0.236473 3.43779 0.184251 3.34949 0.154C3.22105 0.108124 3.08841 0.0749651 2.95349 0.0549998C2.81448 0.0227673 2.67263 0.00434463 2.52999 0C1.859 0 1.21548 0.266552 0.741018 0.741018C0.266552 1.21548 0 1.859 0 2.52999C0.00047939 4.4167 0.633749 6.24873 1.7985 7.73298L3.99849 5.53299ZM10.967 8.05198C10.9439 7.91537 10.9089 7.78103 10.8625 7.65048C10.8322 7.56219 10.78 7.48305 10.7107 7.4205C10.6414 7.35796 10.5574 7.31408 10.4665 7.29298L7.16648 6.53949C7.07691 6.51918 6.98368 6.52162 6.89529 6.5466C6.8069 6.57158 6.72618 6.6183 6.66049 6.68249C6.58349 6.75399 6.57799 6.75949 6.22049 7.44148C5.95141 7.31431 5.69219 7.16723 5.44499 7.00148L3.24499 9.20148C4.73518 10.3709 6.57574 11.0044 8.46998 11C8.80223 11 9.13122 10.9345 9.43817 10.8074C9.74512 10.6802 10.024 10.4939 10.259 10.259C10.4939 10.024 10.6802 9.74512 10.8074 9.43817C10.9345 9.13122 11 8.80223 11 8.46998C11.0001 8.32999 10.9891 8.19021 10.967 8.05198ZM9.75698 1.243C9.71151 1.19743 9.6575 1.16128 9.59805 1.13662C9.53859 1.11195 9.47485 1.09925 9.41048 1.09925C9.34611 1.09925 9.28237 1.11195 9.22291 1.13662C9.16346 1.16128 9.10945 1.19743 9.06398 1.243L1.243 9.06398C1.15225 9.1566 1.10098 9.28082 1.1 9.41048C1.09985 9.5074 1.12848 9.60219 1.18226 9.68282C1.23603 9.76345 1.31254 9.82631 1.40207 9.86342C1.49161 9.90053 1.59015 9.91022 1.6852 9.89128C1.78025 9.87233 1.86754 9.82559 1.936 9.75698L9.75698 1.936C9.80254 1.89053 9.83869 1.83652 9.86336 1.77706C9.88803 1.71761 9.90072 1.65387 9.90072 1.5895C9.90072 1.52513 9.88803 1.46139 9.86336 1.40193C9.83869 1.34247 9.80254 1.28847 9.75698 1.243Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'DropCallIcon'
}
</script>
